<template>
 <div class="mt- overflow-x-auto sm:-mx-6 lg:-mx-0">
  <div class="text-center max-w-lg mx-auto space-y-4">
   <font-awesome-icon
    :icon="
     this.$route.path.includes('answered-unanswered') ||
     this.$route.name === 'ExpertStatisticsCallDurations'
      ? 'chart-column'
      : 'chart-pie'
    "
    class="mx-auto h-10 w-10 text-teal-600"
    aria-hidden="true" />
   <h1 class="text-gray-900 font-bold text-2xl">
    <span v-if="this.$route.path.includes('answered-unanswered')">
     <span v-if="urlType === 'queue'">
      Identifiez et analysez les appels non répondus dans vos files.
     </span>
     <span v-else>
      Identifiez et analysez les appels non répondus par vos utilisateur.
     </span>
    </span>
    <span v-else>
     <span v-if="urlType === 'queue'">
      Comprenez d’où proviennent les appels qui occupent vos files.
     </span>
     <span v-else>
      Comprenez d’où proviennent les appels qui occupent vos utilisateur.
     </span>
    </span>
   </h1>
   <h3 class="mt-2 text-lg font-medium text-gray-900">
    <span v-if="this.$route.path.includes('answered-unanswered')">
     <span v-if="urlType === 'queue'">
      L'analyse par jour vous permet de repérer les jours où vous perdez le plus
      d'appels, tandis que l'analyse par heure identifie les plages horaires où
      les pertes d'appels sont les plus significatives.
     </span>
     <span v-else>
      L'analyse par jour vous permet de repérer les jours où les utilisateurs
      perdent le plus d'appels, tandis que l'analyse par heure identifie les
      plages horaires où les pertes d'appels sont les plus significatives.
     </span>
    </span>
    <span v-else>
     <span v-if="urlType === 'queue'">
      L'analyse par Famille d'Origine vous aide à identifier les catégories
      d'appels qui occupent vos files, qu'ils proviennent de votre SVI, d'une
      autre file, directement de l'externe ou de votre Call Flow. Le TOP 10 met
      en lumière les 10 origines les plus importantes au sein de ces familles.
     </span>
     <span v-else>
      L'analyse par Famille d'Origine vous aide à identifier les catégories
      d'appels qui occupent vos utilisateurs, qu'ils proviennent d’appels
      internes ou externes, de votre SVI, d'une file d’attente ou de votre Call
      Flow. Le TOP 10 met en lumière les 10 appelants les plus fréquents au sein
      de ces familles.
     </span>
    </span>
   </h3>
   <div class="pt-8">
    <PleaseSelectElement
     v-if="urlType === 'queue'"
     dataDescription="Veuillez sélectionner une file et une période" />
    <PleaseSelectElement
     v-else
     dataDescription="Veuillez sélectionner une utilisateur et une période" />
   </div>
  </div>
 </div>
</template>

<script>
import PleaseSelectElement from "../PleaseSelectElement.vue";
export default {
 name: "EmptyStateDashboard",
 props: ["urlType"],
 components: {
  PleaseSelectElement,
 },
 data() {
  return {
   img: {
    src: require("../../assets/empty-state-dashboard.png"),
    src2: require("../../assets/empty-state-dashboard-2.png"),
    alt: "empty-state-dashboards",
   },
  };
 },
};
</script>

<style>
.img_auto_width {
 width: 100%;
}
</style>
