<template>
 <div
  class="absolute z-10"
  @mouseover="showDatePickers = true"
  @mouseleave="showDatePickers = false">
  <div class="flex items-center gap-4">
   <div
    class="flex items-center bg-white shadow-md rounded-md border-gray-400 z-10">
    <p class="border-r px-2 py-2">
     {{
      toLocaleDateString(startDate) +
      (selectedPeriod !== "day" ? " - " + toLocaleDateString(endDate) : "")
     }}
    </p>
    <div
     @click="showDatePickers = true"
     class="px-2 py-2 cursor-pointer bg-gray-400 border-gray-400 text-white hover:bg-blue-400 rounded-r-md">
     <font-awesome-icon icon="calendar" class="flex-shrink-0" />
    </div>
   </div>
  </div>
  <transition name="fade">
   <div
    v-show="showDatePickers"
    class="space-y-1 transition ease-in-out delay-150 mt-1 px-1 text-left">
    <div v-for="option in calendarOptions" :key="option.value">
     <div v-if="option.value === 'custom'">
      <v-date-picker
       class="inline-block h-full w-full"
       color="teal"
       v-model="customDate"
       :max-date="new Date()"
       mode="date"
       is-range>
       <template v-slot="{ togglePopover }">
        <button
         @click="togglePopover()"
         :class="{
          'bg-blue-500 text-white': selectedPeriod === option.value,
          'hover:bg-blue-600 hover:text-white': selectedPeriod !== option.value,
         }"
         class="flex items-center text-xs px-4 py-2 rounded-md focus:outline-none bg-gray-400 text-white w-full text-left">
         <font-awesome-icon :icon="option.icon" class="flex-shrink-0 mr-1.5" />
         {{ $t("dashboards.custom") }}
        </button>
       </template>
      </v-date-picker>
     </div>
     <button
      v-else
      @click="selectPeriod(option.value)"
      :class="{
       'bg-blue-500 text-white': selectedPeriod === option.value,
       'hover:bg-blue-600 hover:text-white': selectedPeriod !== option.value,
      }"
      class="flex items-center text-xs px-4 py-2 rounded-md focus:outline-none bg-gray-400 text-white w-full text-left">
      <font-awesome-icon :icon="option.icon" class="flex-shrink-0 mr-1.5" />
      {{ $t(option.name) }}
     </button>
    </div>
    <div
     v-if="
      this.$route.name !== 'ExpertStatisticsTRTC' &&
      this.$route.name !== 'ExpertStatisticsCallDurations' &&
      this.$route.name !== 'ExpertStatisticsCallDurationsWaitingTime' &&
      this.$route.name !== 'ExpertStatisticsCallerNumbers' &&
      this.$route.name !== 'ExpertStatisticsThirdPartyNumber' &&
      this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekYear' &&
      this.$route.name !== 'ExpertStatisticsAnsweredDaily' &&
      this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekDay'
     "
     class="">
     <TimeSelector
      @timeSelector="$emit('timeSelector', $event)"
      :elements="elements"
      :clearTimeSelector="clearTimeSelector" />
    </div>
   </div>
  </transition>
 </div>
</template>

<script>
import TimeSelector from "./TimeSelector.vue";
import {
 Dialog,
 DialogPanel,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { mapGetters } from "vuex";

const calendarOptions = [
 {
  name: "yesterday",
  value: "yesterday",
  icon: "calendar-day",
 },
 {
  name: "this week",
  value: "currentWeek",
  icon: "calendar-week",
 },
 {
  name: "last week",
  value: "lastWeek",
  icon: "calendar-week",
 },
 {
  name: "this month",
  value: "currentMonth",
  icon: "calendar",
 },
 {
  name: "last month",
  value: "lastMonth",
  icon: "calendar",
 },
 {
  name: "last 3 months",
  value: "last3Months",
  icon: "calendar",
 },
 {
  name: "last 6 months",
  value: "last6Months",
  icon: "calendar",
 },
 {
  name: "personnalisé",
  value: "custom",
  icon: "calendar",
 },
];

export default {
 props: ["periodSelector", "elements", "clearTimeSelector"],
 components: {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  TimeSelector,
 },
 data() {
  return {
   calendarOptions,
   showDatePickers: false,
   customDate: null,
   selectedPeriod: null,
   startDate: null,
   endDate: null,
  };
 },
 methods: {
  selectPeriod(value) {
   this.selectedPeriod = value;
  },
  setPeriod(val) {
   let period = "";
   if (val === "currentWeek") {
    period = this.getCurrentWeek();
   } else if (val === "yesterday") {
    period = this.getYesterday();
   } else if (val === "day") {
    period = this.getCurrentDay();
   } else if (val === "lastWeek") {
    period = this.getLastWeek();
   } else if (val === "currentMonth") {
    period = this.getCurrentMonth();
   } else if (val === "lastMonth") {
    period = this.getLastMonth();
   } else if (val === "last3Months") {
    period = this.getLast3Months();
   } else if (val === "last6Months") {
    period = this.getLast6Months();
   } else {
    period = this.customDate;
   }
   this.startDate =
    val === "custom"
     ? this.parseDateDayMonthYearHifen(period.start)
     : period.firstDay;
   this.endDate =
    val === "custom"
     ? this.parseDateDayMonthYearHifen(period.end)
     : period.lastDay;
   this.$store.dispatch("pbxPeriodSelected", val);
   this.$emit("setPeriod", this.startDate, this.endDate);
  },
 },
 watch: {
  customDate: function (val) {
   if (this.selectedPeriod === "custom") {
    this.setPeriod("custom");
   } else {
    this.selectedPeriod = "custom";
   }
  },
  selectedPeriod: function (val) {
   this.setPeriod(val);
  },
 },
 mounted() {
  this.pbxPeriodSelected
   ? (this.selectedPeriod = this.pbxPeriodSelected)
   : (this.selectedPeriod = "lastMonth");
 },
 computed: {
  ...mapGetters(["pbxPeriodSelected"]),
 },
};
</script>

<style></style>
